// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();
      let isPsEnabled = await store
        .getState()
        .blockchain.smartContract.methods.isPablicSaleEnabled()
        .call();

      let isWlEnabled = false;
      let isVipEnabled = false;
      let isFreeEnabled = false;
      // let isWlEnabled = await store
      // .getState()
      // .blockchain.smartContract.methods.isWlSaleEnabled()
      // .call();
      // let isVipEnabled = await store
      // .getState()
      // .blockchain.smartContract.methods.isVipSaleEnabled()
      // .call();
      // let isFreeEnabled = await store
      // .getState()
      // .blockchain.smartContract.methods.isFreeMintEnabled()
      // .call();
      let maxMintsPerPS = await store
        .getState()
        .blockchain.smartContract.methods.maxMintsPerPS()
        .call();

      // let cost = await store
      //   .getState()
      //   .blockchain.smartContract.methods.cost()
      //   .call();

      dispatch(
        fetchDataSuccess({
          totalSupply,
          isPsEnabled,
          isWlEnabled,
          isVipEnabled,
          isFreeEnabled,
          maxMintsPerPS,
          // cost,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
