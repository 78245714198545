import { AppBar, Typography } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import Box from "@mui/material/Box";
import * as s from "../styles/globalStyles";
import fileDownload from "js-file-download";
import axios from "axios";
import { isMobile } from "react-device-detect";
import { saveAs } from "file-saver";

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

export const StyledInput = styled.input`
  color: #777;
  font-size: 1rem;
  border-radius: 50px;
  padding: 0.6rem 3rem;
  width: 100%;
  position: relative;
  overflow: hidden;
`;
export const BoxList = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  padding: 24px;
  border-radius: 24px;
  border: 4px dashed var(--secondary);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TextDescription = styled.p`
  color: gray;
  font-size: 12px;
  padding: 1rem;
  line-height: 1.6;
`;

export const StyledTitles = styled.img`
  width: 100%;
  position: relative;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
  transition: width 0.5s;
  transition: height 0.5s;
`;
export const StyledTitlesCover = styled.img`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
`;

export const StyledDiverTitle = styled.img`
  width: 1.4rem;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
  transform: rotateY(45deg);
  animation: rotate-anime-3d 3s linear infinite;
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const TitlesTitle = styled.p`
  color: var(--accent-text);
  font-size: 30px;
  font-weight: 500;
  line-height: 1.6;
`;

export const BlinkTitle = styled.p`
  color: var(--accent-text);
  font-size: 30px;
  font-weight: 500;
  line-height: 1.6;
  animation: flash 1s linear infinite;
`;

export const StyledTitlesName = styled.p`
  background-color: var(--accent-text);
  font-size: 24px 0;
  text-align: center;
  text-align: "center";
  font-weight: "bold";
  color: deepskyblue;
  line-height: 2;
`;

export const StyledButtonFree = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 50%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledKeyVisual = styled.img`
  width: 100%;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledButton = styled.button`
  /* padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: blue;
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  } */
  color: #fff;
  font-size: 2rem;
  border-radius: 50px;
  padding: 0.6rem 7rem;
  position: relative;
  overflow: hidden;
  background-color: #ff0000;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-135deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;

export const StyledButtonWall = styled.button`
  color: #fff;
  font-size: 1rem;
  width: 100%;
  border-radius: 10px;
  padding: 0;
  position: relative;
  overflow: hidden;
  background-color: #fff;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
`;
function Titles({ data, config }) {
  const [pumpkin, setpumpkin] = useState(false);
  const [miliSec, setmiliSec] = useState(0);
  const [dlmax, setdlmax] = useState(0);
  const [dlmin, setdlmin] = useState(0);
  const [DownUrl, setDownUrl] = useState(false);
  const [ShareFlag, setShareFlag] = useState(false);
  const [freeText, setfreeText] = useState("");
  const [freeText2, setfreeText2] = useState("");
  const [nengaUrl, setnengaUrl] = useState("");
  const elm = useRef(null);

  const getPumpkin = (pumpkinNum) => {
    setpumpkin(!pumpkinNum);
  };
  const getMiliSec = () => {
    let today = new Date();
    let milliseconds = today.getTime();
    let dlmaxDay = new Date(2023, 0, 20, 23, 59, 59);
    let dlmaxMilliseconds = dlmaxDay.getTime();
    let dlminDay = new Date(2022, 11, 31, 23, 59, 59);
    // let dlminDay = new Date(2022, 11, 31, 2, 59, 59);
    let dlminMilliseconds = dlminDay.getTime();
    setmiliSec(milliseconds);
    setdlmax(dlmaxMilliseconds);
    setdlmin(dlminMilliseconds);
  };

  const handleClick = async (url, filename) => {
    setShareFlag(false);
    if (isMobile) {
      setDownUrl(url);
      elm?.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
      // let newwin = window.open(url);
      // axios
      //   .get(url, {
      //     responseType: "blob",
      //   })
      //   .then((res) => {
      //     // saveAs(
      //     //   new File([res.data], filename, { type: "image/png;charset=utf-8" }),
      //     //   filename
      //     // );

      //     const _url = URL.createObjectURL(
      //       new File([res.data], filename, { type: "image/png;charset=utf-8" })
      //     );
      //     const a = document.createElement("a");
      //     document.body.appendChild(a);
      //     a.download = filename;
      //     a.href = _url;
      //     a.click();
      //     a.remove();
      //   });
    } else {
      alert("ダウンロードします。");
      axios
        .get(url, {
          responseType: "arraybuffer",
        })
        .then((res) => {
          fileDownload(res.data, filename);
        });
    }
    getMiliSec();
  };
  const bgImg = new Image();
  bgImg.src = "/config/images/postcard.png";
  const fukidashi = new Image();
  fukidashi.src = "/config/images/fukidashi.png";
  const chara = new Image();
  chara.crossOrigin = "Anonymous";
  chara.onload = () => {
    setnengaUrl(chara.src);
  };

  const handleClickNenga = async (url, filename) => {
    chara.src = url;
    elm?.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "nearest",
    });
    setDownUrl(false);
    setShareFlag(true);
    getMiliSec();
  };

  // 描画処理
  const drawTextDef = async () => {
    if (ShareFlag) {
      chara.src = nengaUrl;
      const canvas = document.getElementById("canvas");
      const context = canvas?.getContext("2d");
      canvas.setAttribute("crossorigin", "anonymous");
      context.clearRect(0, 0, canvas.width, canvas.height); // クリア
      context.drawImage(bgImg, 0, 0); // 背景画像描画
      context.drawImage(chara, 0, 0); // 背景画像描画
      context.drawImage(fukidashi, 0, 0); // 背景画像描画
      context.fillStyle = "black"; // テキストカラーを指定
      context.font = "60px 'Murecho'"; // フォントを指定
      context.textAlign = "left"; // 左右中央
      context.textBaseline = "middle"; // 上下中央
      context.fillText(freeText, 100, 1600, 1000); // テキスト描画
      context.fillText(freeText2, 100, 1700, 1000); // テキスト描画
      console.log(canvas?.toDataURL());
    }
  };
  // 描画処理
  const drawText = async (text) => {
    chara.src = nengaUrl;
    const canvas = document.getElementById("canvas");
    const context = canvas?.getContext("2d");
    canvas.setAttribute("crossorigin", "anonymous");
    context.clearRect(0, 0, canvas.width, canvas.height); // クリア
    context.drawImage(bgImg, 0, 0); // 背景画像描画
    context.drawImage(chara, 0, 0); // 背景画像描画
    context.drawImage(fukidashi, 0, 0); // 背景画像描画
    context.fillStyle = "black"; // テキストカラーを指定
    context.font = "60px 'Murecho'"; // フォントを指定
    context.textAlign = "left"; // 左右中央
    context.textBaseline = "middle"; // 上下中央
    context.fillText(text, 100, 1600, 1000); // テキスト描画
    context.fillText(freeText2, 100, 1700, 1000); // テキスト描画
    console.log(canvas?.toDataURL());
  };

  // 描画処理
  const drawText2 = async (text) => {
    chara.src = nengaUrl;
    const canvas = document.getElementById("canvas");
    const context = canvas?.getContext("2d");
    canvas.setAttribute("crossorigin", "anonymous");
    context.clearRect(0, 0, canvas.width, canvas.height); // クリア
    context.drawImage(bgImg, 0, 0); // 背景画像描画
    context.drawImage(chara, 0, 0); // 背景画像描画
    context.drawImage(fukidashi, 0, 0); // 背景画像描画
    context.fillStyle = "black"; // テキストカラーを指定
    context.font = "60px 'Murecho'"; // フォントを指定
    context.textAlign = "left"; // 左右中央
    context.textBaseline = "middle"; // 上下中央
    context.fillText(freeText, 100, 1600, 1000); // テキスト描画
    context.fillText(text, 100, 1700, 1000); // テキスト描画
    console.log(canvas?.toDataURL());
  };
  const shareTwitter = async () => {
    console.log("share");
    const canvas = document.getElementById("canvas");
    // Web Share APIの対応判定
    if (navigator.share !== undefined) {
      // CanvasをBlobに変換→pngに変換
      canvas.toBlob((blob) => {
        const shareImg = new File([blob], "share.png", { type: "image/png" });
        // シェア
        navigator.share({
          text: freeText + "\n" + freeText2 + " #DDP #DDPのお正月",
          url: "https://dreamindiverspj.com/",
          files: [shareImg],
        });
      });
    } else {
      var a = document.createElement("a"); //Create <a>
      a.href = canvas?.toDataURL(); //Image Base64 Goes here
      a.download = "NENGA.png"; //File name Here
      a.click(); //Downloaded file
    }
  };

  const setText = async (valueText) => {
    setfreeText(valueText);
    drawText(valueText);
  };
  const setText2 = async (valueText) => {
    setfreeText2(valueText);
    drawText2(valueText);
  };

  useEffect(() => {
    getMiliSec();
  }, []);
  useEffect(() => {
    drawTextDef();
  }, [nengaUrl]);

  return (
    <BoxList>
      <Grid container direction="column">
        <Grid item container>
          <Grid xs={12} sm={12}>
            {data.loading ? (
              <>
                <TitlesTitle
                  id="Titles"
                  style={{
                    textAlign: "center",
                    perspective: "500px",
                    color: "primary.main",
                  }}
                >
                  {"Loading..."}

                  <StyledDiverTitle
                    alt={"logo"}
                    src={"/config/images/gyoro/diamond.png"}
                  />
                  <StyledDiverTitle
                    alt={"logo"}
                    src={"/config/images/gyoro/platina.png"}
                  />
                  <StyledDiverTitle
                    alt={"logo"}
                    src={"/config/images/gyoro/gold.png"}
                  />
                  <StyledDiverTitle
                    alt={"logo"}
                    src={"/config/images/gyoro/silver.png"}
                  />
                  <StyledDiverTitle
                    alt={"logo"}
                    src={"/config/images/gyoro/bronze.png"}
                  />
                </TitlesTitle>
              </>
            ) : (
              <>
                {data.balanceOf > 0 ? (
                  <>
                    <TitlesTitle
                      id="Titles"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {"保有Divers:"}
                      {data.balanceOf}
                      {"体"}
                    </TitlesTitle>
                    {/* <TitlesTitle
                      id="Titles"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <StyledButton
                        onClick={(e) => {
                          e.preventDefault();
                          getPumpkin(pumpkin);
                        }}
                      >
                        🎃
                      </StyledButton>
                    </TitlesTitle> */}

                    <TitlesTitle
                      id="Titles"
                      style={{
                        textAlign: "center",
                        perspective: "500px",
                      }}
                    >
                      {/* :nbronze: @Bronze Diver  …1枚〜2枚
                      :nsilver: @Silver Diver  3枚〜4枚
                      :ngold: @Gold Diver  5枚〜9枚
                      :nplatina: @Platinum Diver  10枚〜14枚
                      :ndiamond: @Diamond Diver  15枚〜 */}
                      {data.balanceOf >= 15 ? (
                        <>
                          <StyledDiverTitle
                            alt={"logo"}
                            src={"/config/images/gyoro/diamond.png"}
                          />
                          {"Diamond Diver"}
                          <StyledDiverTitle
                            alt={"logo"}
                            src={"/config/images/gyoro/diamond.png"}
                          />
                        </>
                      ) : (
                        <>
                          {data.balanceOf >= 10 && data.balanceOf <= 14 ? (
                            <>
                              <StyledDiverTitle
                                alt={"logo"}
                                src={"/config/images/gyoro/platina.png"}
                              />
                              {"Platinum Diver"}
                              <StyledDiverTitle
                                alt={"logo"}
                                src={"/config/images/gyoro/platina.png"}
                              />
                            </>
                          ) : (
                            <>
                              {data.balanceOf >= 5 && data.balanceOf <= 9 ? (
                                <>
                                  <StyledDiverTitle
                                    alt={"logo"}
                                    src={"/config/images/gyoro/gold.png"}
                                  />
                                  {"Gold Diver"}
                                  <StyledDiverTitle
                                    alt={"logo"}
                                    src={"/config/images/gyoro/gold.png"}
                                  />
                                </>
                              ) : (
                                <>
                                  {data.balanceOf >= 3 &&
                                  data.balanceOf <= 4 ? (
                                    <>
                                      <StyledDiverTitle
                                        alt={"logo"}
                                        src={"/config/images/gyoro/siver.png"}
                                      />
                                      {"Silver Diver"}
                                      <StyledDiverTitle
                                        alt={"logo"}
                                        src={"/config/images/gyoro/siver.png"}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      {data.balanceOf < 3 ? (
                                        <>
                                          <StyledDiverTitle
                                            alt={"logo"}
                                            src={
                                              "/config/images/gyoro/bronze.png"
                                            }
                                          />
                                          {"Bronze Diver"}
                                          <StyledDiverTitle
                                            alt={"logo"}
                                            src={
                                              "/config/images/gyoro/bronze.png"
                                            }
                                          />
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </TitlesTitle>
                  </>
                ) : (
                  <>
                    {data.balanceOf === false ? (
                      <>
                        <TitlesTitle
                          id="Titles"
                          style={{
                            textAlign: "",
                          }}
                        >
                          {"ここに保有ダイバーが表示されます。"}
                        </TitlesTitle>
                      </>
                    ) : (
                      <>
                        <TitlesTitle
                          id="Titles"
                          style={{
                            textAlign: "",
                          }}
                        >
                          {"現在保有ダイバーはいません。"}
                        </TitlesTitle>
                      </>
                    )}
                  </>
                )}
              </>
            )}

            <Grid xs={12} sm={12}>
              <Box
                sx={{
                  width: 1 / 1.25,
                  height: 1 / 1,
                  mx: "auto",
                  backgroundColor: "black",
                  "&:hover": {
                    backgroundColor: "primary.main",
                    opacity: [0.9, 0.8, 0.7],
                  },
                }}
              ></Box>
            </Grid>
          </Grid>
          {DownUrl !== false ? (
            <>
              <Grid xs={12} sm={12}>
                <Box
                  sx={{
                    width: 1 / 1,
                    height: 1 / 1,
                    mx: "auto",
                    backgroundColor: "white",
                    "&:hover": {
                      // backgroundColor: "primary.main",
                      opacity: [0.9, 0.8, 0.7],
                    },
                  }}
                  ref={elm}
                >
                  <StyledTitles alt={"wallpaper"} src={DownUrl} />

                  <TextDescription>
                    {"🐰DL方法：画像を長押しして保存してね。"}
                  </TextDescription>
                </Box>
              </Grid>
            </>
          ) : (
            <></>
          )}
          {ShareFlag ? (
            <>
              <Grid xs={12} sm={6}>
                <canvas id="canvas" width="1200" height="1774"></canvas>
                <s.SpacerLarge />
              </Grid>
              <Grid xs={12} sm={6}>
                <s.Container ai={"center"} jc={"center"} fd={"row"}>
                  <StyledInput
                    value={freeText}
                    onChange={(event) => setText(event.target.value)}
                    placeholder="ここにフリーテキストを入力してね"
                  />
                </s.Container>
                <s.Container ai={"center"} jc={"center"} fd={"row"}>
                  <StyledInput
                    value={freeText2}
                    onChange={(event) => setText2(event.target.value)}
                    placeholder="ここにフリーテキストを入力してね"
                  />
                </s.Container>
                <s.Container ai={"center"} jc={"right"} fd={"row"}>
                  <StyledButtonFree
                    onClick={(e) => {
                      e.preventDefault();
                      shareTwitter();
                    }}
                  >
                    {navigator.share !== undefined ? "SHARE" : "DL"}
                  </StyledButtonFree>
                  <s.SpacerLarge />
                </s.Container>
              </Grid>{" "}
            </>
          ) : (
            <></>
          )}

          {data.imageURI != undefined
            ? data.imageURI?.map((imageURI) => (
                <Grid
                  xs={6}
                  sm={2}
                  key={imageURI[0]}
                  style={{
                    perspective: "500px",
                  }}
                >
                  <Box
                    sx={{
                      width: 1 / 1.25,
                      height: 1 / 1,
                      mx: "auto",
                      display: "block",
                      backgroundColor: "none",
                      transformStyle: "preserve-3d",
                      animation: "fadein 3s linear forwards",
                      opacity: "0",
                      animationDelay: imageURI[0] + "s",
                      "&:hover": {
                        backgroundColor: "primary.main",
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                  >
                    <StyledTitles alt={"logo"} src={imageURI[1]} />
                    {pumpkin ? (
                      <>
                        <StyledTitlesCover
                          alt={"logo"}
                          src={"/config/images/pumpkin.png"}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    <StyledLink
                      target={"_blank"}
                      href={config.MARKETPLACE_BASEURI + imageURI[3]}
                    >
                      <StyledTitlesName>{imageURI[2]}</StyledTitlesName>
                    </StyledLink>
                    {dlmax < miliSec || dlmin > miliSec ? (
                      <></>
                    ) : (
                      <>
                        <StyledButtonWall
                          onClick={(e) => {
                            e.preventDefault();
                            handleClick(
                              "https://arweave.net/ZL4NDLfouWbPtRDrgQeu6aGuKPdoZAtgwgvafKS3ibQ/" +
                                String(imageURI[3]) +
                                ".png",
                              "ddpheader_" + String(imageURI[3]) + ".png"
                            );
                          }}
                        >
                          ヘッダーDL🐰
                        </StyledButtonWall>
                        <s.SpacerLarge />

                        <StyledButtonWall
                          onClick={(e) => {
                            e.preventDefault();
                            handleClickNenga(
                              "https://arweave.net/-fcm7MyygiqGItke4ZNF8Jys7WOPVcezpD4BbzVS3X0/" +
                                String(imageURI[3]) +
                                ".png",
                              "ddppostcard_" + String(imageURI[3]) + ".png"
                            );
                          }}
                        >
                          年賀状DL🗻
                        </StyledButtonWall>
                      </>
                    )}
                  </Box>
                </Grid>
              ))
            : ""}

          <Grid xs={12} sm={12}>
            <BlinkTitle
              id="Titles"
              style={{
                position: "relative",
                textAlign: "right",
                top: "100%",
              }}
            >
              {"▼"}
            </BlinkTitle>
          </Grid>

          {/* <Grid xs={12} sm={9}>
            <Box
              sx={{
                width: 1 / 1.25,
                height: 1 / 1,
                mx: "auto",
                backgroundColor: "black",
                "&:hover": {
                  backgroundColor: "primary.main",
                  opacity: [0.9, 0.8, 0.7],
                },
              }}
            >
              <TextDescription>
                {
                  "コレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについて"
                }
                {
                  "コレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについて"
                }
                {
                  "コレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについてコレクションについて"
                }
              </TextDescription>
            </Box>
          </Grid> */}
        </Grid>
      </Grid>
      <s.SpacerLarge />
    </BoxList>
  );
}

export default Titles;
