import { AppBar, Typography } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import Box from "@mui/material/Box";
import * as s from "../styles/globalStyles";

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

export const BoxList = styled.div`
  background-color: rgba(255, 252, 243, 0.7);
  padding: 24px;
  border-radius: 24px;
  border: 4px dashed green;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TextDescription = styled.p`
  color: "#333";
  font-family: Murecho;
  font-weight: bold;
  font-size: 12px;
  line-height: 1.6;
`;

export const StyledTitles = styled.img`
  width: 100%;
  position: relative;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
  transition: width 0.5s;
  transition: height 0.5s;
`;
export const StyledTitlesCover = styled.img`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
`;

export const StyledDiverTitle = styled.img`
  width: 1.4rem;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
  transform: rotateY(45deg);
  animation: rotate-anime-3d 3s linear infinite;
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const TitlesTitle = styled.p`
  color: var(--accent-text);
  font-size: 30px;
  font-weight: 500;
  line-height: 1.6;
`;

export const BlinkTitle = styled.p`
  color: "#333";
  font-size: 30px;
  font-weight: 500;
  line-height: 1.6;
  animation: flash 1s linear infinite;
`;

export const StyledTitlesName = styled.p`
  background-color: var(--accent-text);
  font-size: 24px 0;
  text-align: center;
  text-align: "center";
  font-weight: "bold";
  color: deepskyblue;
  line-height: 2;
`;
export const StyledTitlesNameRed = styled.p`
  background-color: var(--accent-text);
  font-size: 24px 0;
  text-align: center;
  text-align: "center";
  font-weight: "bold";
  color: red;
  line-height: 2;
`;

export const StyledKeyVisual = styled.img`
  width: 100%;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledButton = styled.button`
  color: #fff;
  font-size: 1rem;
  font-family: Murecho !important;
  border-radius: 10px;
  padding: 0.3rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: #fffcf3;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
`;
function Titles({ data, config, dispCntTraits }) {
  return (
    <>
      <BoxList>
        <Grid container direction="column">
          <TitlesTitle
            id="Titles"
            style={{
              textAlign: "center",
            }}
          >
            <StyledButton
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              {dispCntTraits}種類/
              {dispCntTraits >= 12 ? "OK💜" : "足りない😭"}
            </StyledButton>
          </TitlesTitle>
          <Grid item container>
            {Object.keys(data)?.map((key) => (
              <Grid
                xs={4}
                sm={2}
                key={key}
                style={{
                  perspective: "500px",
                }}
              >
                <Box
                  sx={{
                    width: 1 / 1.25,
                    height: 1 / 1,
                    mx: "auto",
                    display: "block",
                    backgroundColor: "#fffcf3",
                    transformStyle: "preserve-3d",
                    animation: "fadein 3s linear forwards",
                    opacity: "0",
                    "&:hover": {
                      backgroundColor: "primary.main",
                      opacity: [0.9, 0.8, 0.7],
                    },
                  }}
                >
                  <StyledTitles
                    alt={"logo"}
                    src={"/config/images/xmasParts/" + key + ".png"}
                  />
                  {data[key] > 0 ? (
                    <>
                      <StyledTitlesNameRed> x {data[key]}</StyledTitlesNameRed>
                    </>
                  ) : (
                    <>
                      <StyledTitlesName> x {data[key]}</StyledTitlesName>
                    </>
                  )}
                </Box>
              </Grid>
            ))}

            <Grid xs={12} sm={12}>
              <BlinkTitle
                id="Traits"
                style={{
                  position: "relative",
                  textAlign: "right",
                  top: "100%",
                }}
              >
                {"▼"}
              </BlinkTitle>
            </Grid>
            <Grid xs={12} sm={12}>
              <Box
                sx={{
                  width: 1 / 1,
                  height: 1 / 1,
                  mx: "auto",
                  // backgroundColor: "black",
                  "&:hover": {
                    backgroundColor: "primary.main",
                    opacity: [0.9, 0.8, 0.7],
                  },
                }}
              >
                <TextDescription>
                  {
                    "🎄スクショをDiscordの「＃DDPのクリスマス」チャンネルに提出しよう❗️"
                  }
                </TextDescription>
              </Box>
              <StyledLink
                target={"_blank"}
                href={"https://discord.gg/dreamindivers-pj"}
              >
                <StyledTitlesName>{"👉Discord👈"}</StyledTitlesName>
              </StyledLink>
            </Grid>
          </Grid>
        </Grid>
        <s.SpacerLarge />
      </BoxList>
      <s.SpacerLarge />
    </>
  );
}

export default Titles;
