// log
import store from "../store";

const fetchDataSubRequest = () => {
  return {
    type: "CHECK_DATASUB_REQUEST",
  };
};

const fetchDataSubSuccess = (payload) => {
  return {
    type: "CHECK_DATASUB_SUCCESS",
    payload: payload,
  };
};

const fetchDataSubFailed = (payload) => {
  return {
    type: "CHECK_DATASUB_FAILED",
    payload: payload,
  };
};

export const fetchDataSub = (account) => {
  return async (dispatch) => {
    dispatch(fetchDataSubRequest());
    try {
      let balanceOf = await store
        .getState()
        .blockchain.smartContract.methods.balanceOf(account)
        .call();

      let tokenURI = [];
      let imageURI = [];
      let tokenId = [];
      let walletOfOwner = await store
        .getState()
        .blockchain.smartContract.methods.walletOfOwner(account)
        .call();
      tokenId = walletOfOwner;

      for (let i = 0; i < balanceOf; i++) {
        tokenURI[i] = await store
          .getState()
          .blockchain.smartContract.methods.tokenURI(tokenId[i])
          .call();

        imageURI[i] = await fetch(
          tokenURI[i].replace("ar://", "https://arweave.net/")
        )
          .then((response) => response.json())
          .then((data) => {
            return [
              i,
              data["image"].replace("ar://", "https://arweave.net/"),
              data["name"],
              tokenId[i],
            ];
          });
      }

      // let cost = await store
      //   .getState()
      //   .blockchain.smartContract.methods.cost()
      //   .call();

      dispatch(
        fetchDataSubSuccess({
          balanceOf,
          tokenURI,
          imageURI,
          // cost,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataSubFailed("Could not load data from contract."));
    }
  };
};
